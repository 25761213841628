<template>
    <Button class="" @click="onButtonClick" >
        <template v-if="ButtonStatus == 2">
            <i class="pi pi-spin pi-spinner"></i>
        </template>
        <template v-else-if="ButtonStatus == 1">
            {{LoadDone}}
        </template>
        <template v-else>
            {{Text}}
        </template>
    </Button>
</template>

<script>
import Button from 'primevue/button'

export default {
    name: 'LoadingButton',
    props: ['Text', 'Loading', 'LoadDone', 'ButtonLoadingStatus'],
    emits: ['onButtonClick'],
    components: {
        Button,
    },
    created() {
    },
    data: function() {
        return {
            ButtonStatus: 0,
        }
    },
    computed: {
    },
    watch: {
        ButtonLoadingStatus: function(newValue) {
            if(newValue == 0) {  // normal
                this.ButtonStatus = 0;
            } else if(newValue == 1) { // load end
                setTimeout(() => {
                    this.ButtonStatus = 1
                    setTimeout(() => {
                        this.ButtonStatus = 0
                    }, 1000)
                }, 600)
            } else if(newValue == 2) { // loading
                this.ButtonStatus = 2
            }
        },
        
    },
    methods: {
        onButtonClick: function(e) {
            this.$emit("onButtonClick", e)
        }
    },
}
</script>




