<template>
    <div style="display:flex; flex-direction: column; height: 100%;">
        <h4 class="p-col-9 text-left p-mb-0 "> {{$t('sentEmailView.title')}}</h4>
        <div class="p-col-9 ">
        <span class="text-left p-pt-0"> {{$t('sentEmailView.info')}}</span>
        <LoadingButton 
              class="p-button-outlined p-button-info p-ml-2  p-pt-0 p-pb-0" 
              @click="onCopyTableButtonClick"
              :LoadDone="$t('sentEmailView.copedTable')"
              :Text="$t('sentEmailView.copyTable')"
              :ButtonLoadingStatus="tableCopyStatus"
        />
        </div>
    <div style="position: relative;flex: 1; width:100%;">
        <DataTable
                ref="datatable"
            style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px;"
            scrollHeight="flex"
            :scrollable="true"
            v-model:value="gridData" 
            selectionMode="single" 
            dataKey="_id" 
            class="p-datatable-sm datatable-width-100"
            v-model:selection="selectedData" 
            v-model:filters="filters1" 
            filterDisplay="menu"
            :globalFilterFields="['ToEmail', 'Subject', 'SeningWorkUserEmail', 'SentDate', 'Text']"
            :lazy="true" :paginator="true" :rows="10"
            :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)"
            >
                <template #empty>
                    {{$t('utils.notFound')}} {{$t('formDataTable.ForgetFilter')}}
                </template>
                <template #loading>
                    Please wait.
                </template>
            <Column :header="$t('sentEmailView.toEmail')" filterField="ToEmail" :showFilterMatchModes="false" >
                <template #body="slotProps" >
                    <span class="text-ellipsis" v-tooltip="slotProps.data.ToEmail">
                        {{slotProps.data.ToEmail}}
                    </span>
                </template>
                    <template #filter="{filterModel}">
                        <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search"  :class="{'p-invalid': filterModel.invalid}"/>
                    </template>
                    <template #filterapply="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('ToEmail', filterModel, filterCallback)" > {{$t("formDataTable.Filter")}} </Button>
                    </template>
                    <template #filterclear="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('ToEmail', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                    </template>
            </Column>
            <Column :header="$t('sentEmailView.subject')"  filterField="Subject" :showFilterMatchModes="false" >
                <template #body="slotProps" >
                    <span class="text-ellipsis" v-tooltip="slotProps.data.Subject">
                        {{slotProps.data.Subject}}
                    </span>
                </template>
                    <template #filter="{filterModel}">
                        <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search"  :class="{'p-invalid': filterModel.invalid}"/>
                    </template>
                    <template #filterapply="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('Subject', filterModel, filterCallback)" > {{$t("formDataTable.Filter")}} </Button>
                    </template>
                    <template #filterclear="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('Subject', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                    </template>
            </Column>
            <Column :header="$t('sentEmailView.sendUser')"  filterField="SeningWorkUserEmail"  :showFilterMatchModes="false" >
                <template #body="slotProps" >
                    <span class="text-ellipsis" v-tooltip="slotProps.data.SeningWorkUserEmail">
                        {{slotProps.data.SeningWorkUserEmail}}
                    </span>
                </template>
                    <template #filter="{filterModel}">
                        <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search"  :class="{'p-invalid': filterModel.invalid}"/>
                    </template>
                    <template #filterapply="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('SeningWorkUserEmail', filterModel, filterCallback)" > {{$t("formDataTable.Filter")}} </Button>
                    </template>
                    <template #filterclear="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('SeningWorkUserEmail', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                    </template>
            </Column>
            <Column :header="$t('sentEmailView.sendDate')" headerStyle="width: 140px" style="max-width: 140px;" filterField="SentDate" dataType="date" :showFilterOperator=false  >
                <template #body="slotProps" >
                    {{ $filters.formatDate(slotProps.data.SentDate, null, "HH:mm:ss") }} 
                </template>
                    <template #filter="{filterModel}">
                        <Calendar v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" dateFormat="yy/mm/dd" placeholder="yyyy/mm/dd"  :class="{'p-invalid': filterModel.invalid}"/>
                    </template>
                    <template #filterapply="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('SentDate', filterModel, filterCallback)" > {{$t("formDataTable.Filter")}} </Button>
                    </template>
                    <template #filterclear="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('SentDate', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                    </template>
            </Column>
            <Column :header="$t('sentEmailView.content')"  filterField="Text"  :showFilterMatchModes="false">
                <template #body="slotProps" >
                    <span class="text-ellipsis" v-tooltip="slotProps.data.Text">
                        {{slotProps.data.Text}}
                    </span>
                </template>
                    <template #filter="{filterModel}">
                        <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search"  :class="{'p-invalid': filterModel.invalid}"/>
                    </template>
                    <template #filterapply="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('OpenEnd', filterModel, filterCallback)" > {{$t("formDataTable.Filter")}} </Button>
                    </template>
                    <template #filterclear="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('OpenBegin', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                    </template>
            </Column>
        </DataTable>
    </div>
    </div>
    <mailDialog :displayDialog="showMailDialog" @closeDialog="closeMailDialog($event)" :readOnly=true />
    <div v-if="Loading" class="progressSpinnerContainer" style="z-index: 2;">
        <ProgressSpinner />
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import Tooltip from 'primevue/tooltip'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import LoadingButton from '@/components/LoadingButton'


import mailDialog from '@/components/mailDialog'
import ProgressSpinner from 'primevue/progressspinner';
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import helper from "@/mixins/helper"


export default {
    name: 'SentEmailView',
    components: {
        DataTable,
        Column,
        mailDialog,
        InputText,
        Button,
        Calendar,
        ProgressSpinner,
        LoadingButton,
    },
    created() {
        this.initFilter(false)
        this.fetchData()
    },
    data: function() {
        return {
            selectedData: null,
            showMailDialog: false,
            filters1: {},
            loading: false,
            Loading: false,
            tableCopyStatus: 0,
        }
    },
    computed: {
        gridData: {
            get: function() {
                return this.$store.getters["sentMailStore/getMailList"]
            },
        },
        totalRecords: {
            get: function() {
                const gridTotalRecords = this.$store.getters["sentMailStore/getGridDataTotalRecords"]
                return gridTotalRecords
            },
        },
    },
    watch: {
        selectedData: async function(data) {
            if(data) {
                this.Loading = true
                await this.$store.dispatch("sentMailStore/getMailDataById", data._id)
                this.Loading = false
                this.showMailDialog = true;
            }
        },
    },
    methods: {
        initFilter: function(forceRefrech=true) {
            if(!forceRefrech) {
                const filterData = this.$store.getters["sentMailStore/getGridFilterData"]
                console.log(filterData)
                if(filterData && filterData.ToEmail) {
                    this.filters1 = filterData;
                    return;
                }
            }
            const DateFilterList = [
                {value: null, matchMode: FilterMatchMode.DATE_IS},
            ]
            const DateFilter = {operator: FilterOperator.AND, constraints: DateFilterList};
            this.filters1 = {
                'ToEmail': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'Subject': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'SeningWorkUserEmail': {value: null, matchMode: FilterMatchMode.CONTAINS}, 
                'SentDate': DateFilter, 
                'Text': {value: null, matchMode: FilterMatchMode.CONTAINS}
            }
        },

        filterOn: function() {
            const self = this
            this.$nextTick(function() {
                if(self.$refs.filterInputRef.$el.children && self.$refs.filterInputRef.$el.children.length)
                    self.$refs.filterInputRef.$el.children[0].focus()
                else
                    self.$refs.filterInputRef.$el.focus()
            })
        },

        async fetchData() {
            this.Loading = true
            await this.$store.dispatch('sentMailStore/getMailList')
            this.Loading = false
        },

        async closeMailDialog() {
            this.showMailDialog = false
            this.selectedData = null
        },

        async onCopyTableButtonClick() {
            await(this.tableCopyStatus = 2)
            const gridData = this.$refs.datatable.getProcessedData()
            let copyText = ""
            const divi = "\t"
            for(let i = 0; i < gridData.length; i++) {
                const data = gridData[i]
                let subject = data["Subject"]
                subject = subject.replace(/"/g, '""');
                if (subject.search(/("|,|\n\t)/g) >= 0) {
                    subject = '"' + subject + '"';
                }

                let text = data["Text"]
                text = text.replace(/"/g, '""');
                text = text.replace(/[\n\t]+/g, ' ')
                if (text.search(/("|,|\n)/g) >= 0) {
                    text = '"' + text + '"';
                }
                copyText += data["ToEmail"] + 
                    divi + 
                    (subject ? subject : "") + 
                    divi +
                    (data["SeningWorkUserEmail"] ? data["SeningWorkUserEmail"] : "") + 
                    divi +
                    this.$filters.formatDate(data["SentDate"], null, "HH:mm:ss") +
                    divi +
                    text + "\n"
            }
            helper.copyTextToClipboard(copyText ? copyText : " ")
            this.tableCopyStatus = 1
        },

        onPage(e) {
            this.$store.commit("sentMailStore/setGridPageData", {gridPageData: e.page})
        this.fetchData()
        },

        onSort(e) {
            this.$store.commit("sentMailStore/setGridSortData", {gridSortField: e.sortField, gridSortOrder: e.sortOrder})
        this.fetchData()
        },

        onFilter(e) {
            console.log(e)
            this.$store.commit("sentMailStore/setGridFilterData", {gridFilterData: e.filters})

            this.onPage({Type: this.Type, Status: this.Status,  gridPageData: 0})
        },

        gridFilterCallback(type, filterModel, filterCallback) {
            if(filterModel.constraints) {
                let clear = true
                for(let i = 0; i < filterModel.constraints.length; i++) {
                    if(!filterModel.constraints[i].value) {
                        filterModel.constraints[i].invalid = true
                        clear = false
                    } else {
                        filterModel.constraints[i].invalid = false
                    }
                }
                if(clear) {
                    filterCallback()
                }
            } else if(filterModel.value) {
                filterModel.invalid = false
                filterCallback()
            } else {
                filterModel.invalid = true
            }
        },

        gridFilterClearCallback(type, filterModel, filterCallback) { 
            if(filterModel.constraints) {
                for(let i = 0; i < filterModel.constraints.length; i++) {
                    filterModel.constraints[i].invalid = false
                }
            } else {
                filterModel.invalid = false
            }
            filterCallback()
        },
    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>



